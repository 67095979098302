import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./results.css";
import Header from "./header";

function ProgressBar({percentage}) {
  return (
    <div className="progress-bar">
      <div className="progress-bar__fill" style={{width: `${percentage}%`}}>
        <span className="progress-bar__text">{percentage}%</span>
      </div>
    </div>
  );
}
const Results = () => {
  const name = useParams().name.split("@")[0];
  const lang = useParams().name.split("@")[1];

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = 'Mondonomo Homophones Experiment';

    setIsLoading(true);
    axios.get(`https://mondonomo.ai/homophones/get_homophones`, {
      params: {
        name,
        lang,
      },
      headers: {
        Authorization: `Token 309c661a4047ca231856e296f5875f5f1e017541`,
      },
    }).then((response) => {
      setData(response.data);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  }, [lang, name]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
   <div className="container">
      <Header />

      <h2 className="h1">Homophones for the {data.lang} first name <em>{data.name}</em>  /{data.ipa}/</h2>

      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Name</th>
            <th>Language</th>
            <th>Phonetic</th>
            <th>Similarity</th>
            <th>Popularity</th>
          </tr>
        </thead>
        <tbody>
        {data.homophones.map((n) => (
          <tr>
            <td>{n.name}</td>
            <td>{n.lang}</td>
            <td>/{n.ipa}/</td>
            <td><ProgressBar percentage={n.similarity} /></td>
            <td><ProgressBar percentage={n.frequency} /></td>
          </tr>

     ))}
        </tbody>
      </table>
      {/*<div><pre>{JSON.stringify(data, null, 2)}</pre></div>*/}
    </div>
  );
};

export default Results;
