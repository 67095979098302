import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import Downshift from 'downshift';
import './autocomplete.css'

const TypeAhead = () => {
    const [inputValue, setInputValue] = useState('');
    const [results, setResults] = useState([]);
    const [debouncedInputValue] = useDebounce(inputValue, 300);

    useEffect(() => {
        if (debouncedInputValue) {
         axios.get(`https://mondonomo.ai/get_entity`, {
            params: {
                text: debouncedInputValue,
                type: 'ipa',
                intent: 'autocomplete',
                max_results: 10,
            },
            headers: {
                Authorization: `Token 309c661a4047ca231856e296f5875f5f1e017541`,
                 }
            })
            .then(res => {
                setResults(res.data.items || []);
            })
            .catch(err => console.error(err));
        } else {
            setResults([]);
        }
    }, [debouncedInputValue]);

    return (
        <Downshift
            inputValue={inputValue}
            //onChange={selection => setInputValue(selection.label)}
            onChange={selection => window.location.href = '/name/'+selection.value }
            itemToString={item => (item ? item.label : '')}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                isOpen,
                selectedItem
            }) => (
                <div className="typeahead-container">
                    <input {...getInputProps({
                        onChange: e => setInputValue(e.target.value),
                        placeholder: 'Type a given name...',
                        className: 'typeahead-input',
                        })}
                        autoFocus
                        />

                    <ul {...getMenuProps({className: 'typeahead-results'})}>
                        {isOpen && results.map((item, index) => (
                            <li {...getItemProps({
                                key: item.value,
                                index,
                                item,
                                style: {
                                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                },
                            })} >
                                <div className={'itemLabel'}>{item.label}</div>
                                <div className={'itemDesc'}>{item.language}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Downshift>
    );
};

export default TypeAhead;
