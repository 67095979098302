import React from 'react';
import './header.css';

const Header = () => (
  <header className="header">
    <nav className="header-nav">
        <div className="header-logo"><a href="/">Mondonomo homophone experiment</a></div>
    </nav>

  </header>
);

export default Header;
