import React, { useMemo, useEffect, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters
} from 'react-table';

const styles = {
  countries:{
    padding: 10,
  },
  table: {
    borderSpacing: 0,
    border: '1px solid black',
    width: '100%',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
  },
  cell: {
    padding: '10px',
    border: '1px solid gray',
    textAlign: 'left',
  },
  header: {
    backgroundColor: '#F2F2F2',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#4CAF50',
    border: 'none',
    color: 'white',
    padding: '8px 16px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    transitionDuration: '0.4s',
    cursor: 'pointer',
    borderRadius: '12px',
  },
  buttonDisabled: {
    backgroundColor: '#dddddd',
    color: 'black',
    cursor: 'not-allowed',
  },
};

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
  } = useTable({ columns,
        data,
        initialState: { sortBy: [{ id: 'match', desc: true }] },
        defaultColumn: { Filter: DefaultColumnFilter } // Set Default Filter UI
     },
      useFilters, useSortBy, usePagination);

  const { pageIndex } = state;

  return (
    <div style={styles.countries}>
      <table {...getTableProps()} style={styles.table}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{...styles.cell, ...styles.header}}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{...styles.cell}}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={!canPreviousPage ? {...styles.button, ...styles.buttonDisabled} : styles.button}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage} style={!canPreviousPage ? {...styles.button, ...styles.buttonDisabled} : styles.button}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage} style={!canNextPage ? {...styles.button, ...styles.buttonDisabled} : styles.button}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={!canNextPage ? {...styles.button, ...styles.buttonDisabled} : styles.button}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
      </div>
    </div>
  );
}


export default function CC2CC() {
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: 'Country',
        accessor: 'country1',
        Filter: DefaultColumnFilter, // Apply the filter to the first column
      },
      {
        Header: 'Country',
        accessor: 'country2',
        disableFilters: true,

      },
      {
        Header: 'Match',
        accessor: 'match',
        defaultSortDesc: true,
        disableFilters: true,

      },
    ],
    []
  );

  useEffect(() => {
  // assuming data.json is in public folder
  fetch('/cc2cc.json')
    .then((response) => response.json())
    .then((data) => {
      data.sort((a, b) => b.match - a.match) // Sort by 'match' descending
      setData(data)
    });
    }, []);

  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  );
}
