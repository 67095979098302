import React, { useState, useEffect } from "react";
import TypeAhead from "./autocomplete";
import Header from "./header";

import "./index.css"
const App = () => {
  return (
      <div className="container">
      <Header />
    <div className="search-bar">
      <TypeAhead
        onSelect={(suggestion) => {
          console.log(suggestion);
        }}
      />
    </div>
      </div>
  );
};

export default App;
